import React, { Fragment, useEffect, useReducer, useRef, useState } from 'react';
import { GetServerSideProps } from 'next';
import getConfig from 'next/config';
import Head from 'next/head';
import {
  MotionValue,
  motion,
  useElementScroll,
  useMotionValue,
  useTransform,
  useViewportScroll,
} from 'framer-motion';
import { ToastsV2 } from '@zola/zola-ui/src/components/ToastsV2/index';
import { ToastV2Options } from '@zola/zola-ui/src/components/ToastsV2/types/ToastV2Options';
import { toastsActions, toastsReducer } from '@zola-helpers/client/dist/es/redux/toasts';

import { AppDownloadBannerMobile } from '@zola/zola-ui/src/components/AppDownloadBannerMobile/AppDownloadBannerMobile';
import Nav from 'components/Nav';
import ZolaDifference from 'components/LP/Homepage3.0/components/ZolaDifference';
import MeetTeamZ from 'components/LP/Homepage3.0/components/MeetTeamZ';
import Hero from 'components/LP/Homepage3.0/components/Hero';
import SolutionGrid2 from 'components/LP/Homepage3.0/components/SolutionGrid';
import ProductPanel2 from 'components/LP/Homepage3.0/components/ProductPanel/ProductPanel2';
import { PreauthFooterV3 } from '@zola/zola-ui/src/components/PreAuthV3';

import useRefScrollProgress from 'hooks/useRefScrollProgress';

import type IncomingMessageWithServerTiming from 'types/IncomingMessageWithServerTiming';

import { fetchNavHTML } from 'libs/server/nav';
import { fetchUser } from 'libs/server/users';
import { UserContext } from '@zola-helpers/client/dist/es/@types';

import { getIsGuest } from 'libs/universal/utils/getIsGuest';
import { renderJsonLdScript } from 'libs/universal/structuredData';
import { HOMEPAGE_SCHEMA } from 'constants/schemas/homepageSchema';
import { makeQueryString } from 'libs/server/utils/url';
import _assign from 'lodash/assign';

import { ServerResponse } from 'http';
import { ParsedUrlQuery } from 'querystring';

import { excludeMobile, mobileOnly } from '@zola/zola-ui/src/styles/emotion/mixins/responsive';
import getQueryParam from '@zola-helpers/client/dist/es/util/getQueryParam';
import { useResponsiveDesign } from '@zola/zola-ui/src/contexts/ResponsiveDesignContext/ResponsiveDesignContext';
import { ResponsiveDesignProvider } from '@zola/zola-ui/src/contexts/ResponsiveDesignContext/ResponsiveDesignProvider';

import { VendorCategories } from 'components/LP/Homepage3.0/components/VendorCategories/VendorCategories';
import { VendorCrossLinks } from 'components/LP/Homepage3.0/components/VendorCrossLinks/VendorCrossLinks';
import { WSearchLocationView } from '@zola/svc-web-api-ts-client';
import { fetchTopSearchLocations } from 'libs/server/seo';
import { ContentWrapper, FooterWrapper, HomepageWrapper } from './homepage.styles';

const title = 'Wedding Planning: Website, Invites, Venues, Registry, & More';
const description =
  'Your one-stop destination for all things wedding. Free wedding website, registry, and tools. Find the perfect venue. Shop invites. Your wedding starts here!';

type HomepageInnerProps = {
  navHTML: string | null;
  userCtx: UserContext | null;
  topLocations: WSearchLocationView[];
};

type GridProps = {
  scrollYProgress: MotionValue<number>;
};

const MobileGrid: React.FC<GridProps> = ({ scrollYProgress }): JSX.Element => {
  const { isMobile, isTablet } = useResponsiveDesign();

  const solutionGridProps = {
    isMobile,
    isTablet,
    scrollYProgress,
  };

  return (
    <div css={mobileOnly}>
      <SolutionGrid2 {...solutionGridProps} />
    </div>
  );
};

const NonMobileGrid: React.FC<GridProps> = ({ scrollYProgress }): JSX.Element => {
  const animationSectionRef = useRef<HTMLDivElement>(null);
  const { isMobile, isTablet } = useResponsiveDesign();
  const endKeyframe = isMobile || isTablet ? 0.07 : 0.3;
  const initialScale = 0.75;

  const animationInput = useRefScrollProgress(animationSectionRef, [0, endKeyframe], isTablet);
  const scale = useTransform(scrollYProgress, animationInput, [initialScale, 1]);
  const gridView = useTransform(scrollYProgress, animationInput, [0, 1]);

  const solutionGridProps = {
    isMobile,
    isTablet,
    scrollYProgress,
  };

  return (
    <div id="product-grid" ref={animationSectionRef} css={excludeMobile}>
      <motion.div style={{ margin: '0 auto', maxWidth: '1920px', scaleX: scale }}>
        <ContentWrapper>
          <motion.div style={{ gridColumn: 1, gridRow: 1, opacity: gridView, zIndex: gridView }}>
            <SolutionGrid2 {...solutionGridProps} />
          </motion.div>
        </ContentWrapper>
      </motion.div>
    </div>
  );
};

const HomepageInner: React.FC<HomepageInnerProps> = ({
  navHTML,
  userCtx,
  topLocations,
}): JSX.Element => {
  const { isMobile, isTablet } = useResponsiveDesign();
  const homepageRef = useRef<HTMLDivElement>(null);

  const isGuest = getIsGuest(userCtx);

  // Users who try to reset their password can be redirected to the homepage.  Show them a toast message.
  const [state, dispatch] = useReducer(toastsReducer, []);
  useEffect(() => {
    const resetStatus = getQueryParam('reset_status');
    if (resetStatus === 'invalid') {
      dispatch(
        toastsActions.negative({
          autoDismissInSeconds: 0,
          dismissText: 'Close',
          headline:
            'Your reset password link is invalid or expired. Please request your password again.',
        })
      );
    }
    if (resetStatus === 'error') {
      dispatch(
        toastsActions.negative({
          autoDismissInSeconds: 0,
          dismissText: 'Close',
          headline: 'An error occurred while resetting your password.  Please try again.',
        })
      );
    }
    if (resetStatus === 'success') {
      dispatch(
        toastsActions.positive({
          autoDismissInSeconds: 3,
          headline: 'Your password has been reset.',
        })
      );
    }
    if (resetStatus === 'logout') {
      dispatch(
        toastsActions.positive({
          autoDismissInSeconds: 5,
          headline: 'Password changed and you’ve been signed out of all devices.',
        })
      );
    }
  }, []);

  // useElementScroll needs overflow-y set to auto or scroll but on desktop,
  // this creates a double scrollbar with strange nav bar interactions
  const mobileTabletScroll = useElementScroll(homepageRef);
  // useViewportScroll doesn't seem to work on Tablet or Mobile views so make the
  // scroll tracking dependent on device
  const desktopScroll = useViewportScroll();

  const motionValue = useMotionValue(0);
  const [scrollYProgress, setScrollYProgress] = useState(motionValue);
  useEffect(() => {
    setScrollYProgress(
      isMobile || isTablet ? mobileTabletScroll.scrollYProgress : desktopScroll.scrollYProgress
    );
  }, [isMobile, isTablet, mobileTabletScroll, desktopScroll]);

  const Grid = isMobile ? MobileGrid : NonMobileGrid;

  return (
    <Fragment>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta
          property="og:image"
          content="https://d1tntvpcrzvon2.cloudfront.net/static-assets/images/logos/zola-heart-marine.png"
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="zola.com" />
        <meta name="twitter:site" content="@zola" />
        <meta name="twitter:card" content="summary" />
        <link rel="canonical" href="https://www.zola.com" />
        {renderJsonLdScript(HOMEPAGE_SCHEMA)}
      </Head>
      {isGuest ? <AppDownloadBannerMobile campaignName="zola_web_lp_preauth" /> : <></>}
      <Nav navHTML={navHTML} showPromo user={userCtx} disablePrimaryNavCollapse disableSecondary />
      <ToastsV2
        toasts={state as ToastV2Options[]}
        onRemoveToast={(id: number) => dispatch(toastsActions.hide(id))}
      />
      <HomepageWrapper ref={homepageRef}>
        <Hero />
        <Grid scrollYProgress={scrollYProgress} />
        <ProductPanel2 scrollYProgress={scrollYProgress} />
        <ZolaDifference />
        <MeetTeamZ />
        <VendorCategories />
        <VendorCrossLinks topLocations={topLocations} />
        <FooterWrapper>
          <PreauthFooterV3 isGuest={isGuest} />
        </FooterWrapper>
      </HomepageWrapper>
    </Fragment>
  );
};

type HomepageProps = {
  navHTML: string | null;
  userCtx: UserContext | null;
  userAgent?: string;
  topLocations: WSearchLocationView[];
};

const Homepage: React.FC<HomepageProps> = props => {
  const { userAgent } = props;
  return (
    <ResponsiveDesignProvider userAgent={userAgent}>
      <HomepageInner {...props} />
    </ResponsiveDesignProvider>
  );
};

export const getServerSideProps: GetServerSideProps = async ({
  req,
  query,
  res,
}: {
  req: IncomingMessageWithServerTiming;
  query: ParsedUrlQuery;
  res: ServerResponse;
}) => {
  const userCtx = await fetchUser(req);
  const userAgent = req.headers['user-agent'];

  const sendRedirectLocation = (location: string) => {
    res.writeHead(302, {
      Location: location,
    });
    res.end();
    return { props: {} }; // stop execution
  };

  const redirectToPlanningDash = () => {
    const queryFinal = _assign({}, query, { redirect: true });
    const redirectQueryString = makeQueryString(queryFinal);
    return sendRedirectLocation(`/wedding/manage${redirectQueryString}`);
  };

  const redirectToBaby = () => {
    const { publicRuntimeConfig } = getConfig();
    const redirectQueryString = makeQueryString(query);
    return sendRedirectLocation(`${publicRuntimeConfig.BABY_HOST}${redirectQueryString}`);
  };

  if (userCtx && !userCtx.is_guest && userCtx.has_completed_onboarding) {
    redirectToPlanningDash();
  }

  // Redirect to baby
  const { twofasuccess } = query; // proxy for came from login
  if (userCtx && !userCtx.is_guest && userCtx.is_baby_registry_only && twofasuccess === 'true') {
    redirectToBaby();
  }

  const [navHTML, topLocations] = await Promise.all([fetchNavHTML(req), fetchTopSearchLocations()]);

  return {
    props: {
      navHTML,
      userCtx,
      userAgent,
      topLocations,
    },
  };
};

export default Homepage;
